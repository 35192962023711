<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="manHourFill">
		<div class="manHourFill-content">
			<div class="manHourFill-search">
				<div class="common-row">
					<!-- <div class="common-row-item">
						<span class="common-row-label">招募名称</span>
						<el-input class="common-row-ipt" placeholder="请输入招募名称" v-model="searchForm.recruitName" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">招募ID</span>
						<el-input class="common-row-ipt" placeholder="请输入招募ID" v-model="searchForm.recruitId" />
					</div> -->
					<div class="common-row-item">
						<span class="common-row-label">活动ID</span>
						<el-input class="common-row-ipt" placeholder="请输入活动ID" v-model="searchForm.activityId" />
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">活动类型</span>
						<el-select @change="activityTypeChange" multiple class="common-row-ipt" v-model="activityTypeSelect" placeholder="请选择">
							<el-option v-for="item in activityTypeList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">组织名称</span>
						<el-select class="common-row-ipt" v-model="searchForm.organizationId" placeholder="请选择">
							<el-option v-for="item in originList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
				</div>
			</div>
			<div class="manHourFill-table">
				<el-table :data="tableData" border>
					<el-table-column prop="id" label="活动ID">
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
					</el-table-column>
					<el-table-column width="280px" prop="activityTime" label="活动时间">
						<!-- <template slot-scope="scope">
							<span
								v-for="(item, index) in scope.row.activityTimeAdresses">{{`${dateFormat(item.starTime)} 至 ${dateFormat(item.endTime)}`}}<span
									v-if="index != scope.row.activityTimeAdresses.length - 1">
								</span></span>
						</template> -->
						<template slot-scope="scope">
							<span>{{`${dateFormat(scope.row.starTime)} 至 ${dateFormat(scope.row.endTime)}`}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="activityStatus" label="活动状态">
						<template v-if="scope.row.activityStatus*1 >= 0" slot-scope="scope">
							<div v-if="scope.row.activityStatus == 2" class="table-action">
								<span
									:class="{'table-action-status_orange': scope.row.workingStatus == 1,'table-action-status_red': scope.row.workingStatus == 2,'table-action-status_black': scope.row.workingStatus == 3}">{{scope.row.workingStatus == 1 ?"工时表待提交":scope.row.workingStatus == 2 ?"工时表超时未提交":"工时表已提交"}}</span>
							</div>
							<div v-else class="table-action">
								<span
									:class="{'table-action-status_black': scope.row.activityStatus != 0,'table-action-status_red': scope.row.activityStatus == 0}">{{scope.row.activityStatus == 0?"未通过":scope.row.activityStatus == 1?"审核中":scope.row.activityStatus == 2?"进行中":"已完成"}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="activityType" label="活动分类">
						<template slot-scope="scope">
							<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="organizer" label="组织名称">
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('detail', scope)">详情</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div v-if="totalCount > 10" class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		fillManhour
	} from "@/api/manhour";
	import {
		searchActivity
	} from "@/api/activity";
	import {
		getDepartmentList,
		allOrigin
	} from "@/api/system";
	export default {
		data() {
			return {
				tableData: [],
				searchForm: {},
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				activityTypeSelect: [],
				activityTypeList: [{
						label: "一般活动A类",
						value: "1"
					},
					{
						label: "一般活动B类",
						value: "3"
					},
					{
						label: "共建基地活动",
						value: "2"
					},
				],
				typeList: [{
						label: "一般活动",
						value: "GENERALLY"
					},
					{
						label: "共建基地活动",
						value: "CO_BASE"
					},
				],
				originList: [],
			};
		},
		mounted() {
			this.getAllOriginList()
			// this.getManhourFill()
		},
		methods: {
			activityTypeChange(e) {
				console.log("activityTypeChange", e)
				if(e.length > 0) {
					this.$set(this.searchForm, "activityType", e.join(","))
				} else {
					this.$set(this.searchForm, "activityType", "")
				}
			},
			changeTab() {},
			getAllOriginList() {
				allOrigin().then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				} else {
					return ""
				}
			},
			// 获取分会列表
			departmentList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
					type: "SUB_COMP"
				}
				this.originList = [];
				getDepartmentList(params).then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			// 工时补传列表
			getManhourFill() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					workingStatus: 3
				}
				if (this.searchForm.recruitName) {
					this.$set(params, "recruitName", this.searchForm.recruitName)
				}
				if (this.searchForm.recruitId) {
					this.$set(params, "recruitId", this.searchForm.recruitId)
				}
				if (this.searchForm.activityId) {
					this.$set(params, "activityId", this.searchForm.activityId)
				}
				if (this.searchForm.activityType) {
					this.$set(params, "activityType", this.searchForm.activityType)
				}
				if (this.searchForm.organizationId) {
					this.$set(params, "organizationId", this.searchForm.organizationId)
				}
				if (JSON.stringify(this.searchForm) != '{}') {
					searchActivity(params).then((res) => {
						if (res.code == 0) {
							this.tableData = res.data
							this.totalCount = res.page.totalCount * 1
						}
					})
					// fillManhour(params).then((res) => {
					// 	if (res.code == 0) {
					// 		res.data.forEach(item => {
					// 			if (item.activityTimeAdresses && item.activityTimeAdresses.length > 0) {
					// 				item.activityTimeAdresses.forEach(citem => {
					// 					this.$set(item, "activityTime",
					// 						`${this.dateFormat(citem.activityTimeStart)}~${this.dateFormat(citem.activityTimeEnd)},`
					// 					)
					// 				})
					// 			} else {
					// 				this.$set(item, "activityTime", "")
					// 			}
					// 		})
					// 		this.tableData = res.data
					// 		this.totalCount = res.page.totalCount * 1
					// 	}
					// })
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getManhourFill();
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.totalCount = 0
				this.getManhourFill();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.totalCount = 0
				this.activityTypeSelect = []
				// this.getManhourFill();
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						// let routeEdit = this.$router.resolve({
						// 	path:"/manHourDelete/detail",
						// 	query:{detailId:scope.row.id}
						// })
						// window.open(routeEdit.href,"_blank")
						this.$router.push({
							path: `/manHourDelete/detail?detailId=${scope.row.id}`
						})
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	.table-action {
		.table-action-status_black {
			color: #303133;
		}

		.table-action-status_orange {
			color: #f5803e;
		}

		.table-action-status_red {
			color: #e63757;
		}
	}

	.manHourFill {
		.manHourFill-content {
			padding: 20px 20px;

			.manHourFill-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.manHourFill-table {
				margin-top: 20px;
			}
		}
	}
</style>
