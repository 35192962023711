/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-08-05 16:23:45
 * @LastEditTime: 2022-08-29 15:53:52
 */
import axios from '@/utils/request'
let Api = {
	noticeList: "/announcementManage/list",
	noticeDetail: "/announcementManage/",
	noticeEdit: "/announcementManage/",
	roleList: "/iam/role/list",
	departmentList: "/iam/org/list",
	permissionTree: "/iam/resourcePermission/list",
	originAdd: "/iam/org/",
	originDetail: "/iam/org/",
	originEdit: "/iam/org/",
	bmMemeberAdd: "/iam/role/",
	bmMemeberDetail: "/iam/role/",
	bmMemeberEdit: "/iam/role/",
	accountList: "/iam/user/list",
	accountAdd: "/iam/user/",
	accountDetail: "/iam/user/",
	accountEdit: "/iam/user/",
	fhRolesList: "/iam/role/getSubCompRole",
	miniprogramImg: "/announcementManage/indexImg",
	miniprogramImgChange: "/announcementManage/changeIndexImg",
	handleLogList: "/iam/operationLog/list",
	passwordChange: "/iam/user/changePwd",
	originAll: "/iam/org/allOrg",
	accountDisable: "/iam/user/disabled/",
	rolesDelete: "/iam/org/",
	bmMemberDelete: "/iam/role/",
	accountDelete: "/iam/user/",
}
// 公告列表
export function getNoticeList(params) {
	return axios({
		method: 'get',
		url: Api.noticeList,
		params: params
	})
}
// 公告详情
export function detailNotice(id, params) {
	return axios({
		method: 'get',
		url: Api.noticeDetail + id,
		params: params
	})
}
// 公告编辑
export function editNotice(id, params) {
	return axios({
		method: 'put',
		url: Api.noticeEdit + id,
		data: params
	})
}
// 活动列表
export function getRoleList(params) {
	return axios({
		method: 'get',
		url: Api.roleList,
		params: params
	})
}
// 部门列表
export function getDepartmentList(params) {
	return axios({
		method: 'get',
		url: Api.departmentList,
		params: params
	})
}
// 权限树
export function getPermissionTree(params) {
	return axios({
		method: 'get',
		url: Api.permissionTree,
		params: params
	})
}
// 组织新增
export function addOrigin(params) {
	return axios({
		method: 'post',
		url: Api.originAdd,
		data: params
	})
}
// 组织详情
export function detailOrigin(id, params) {
	return axios({
		method: 'get',
		url: Api.originDetail + id,
		params: params
	})
}

// 组织编辑
export function editOrigin(id, params) {
	return axios({
		method: 'put',
		url: Api.originEdit + id,
		data: params
	})
}
// 部门成员新增
export function addbmMemeber(params) {
	return axios({
		method: 'post',
		url: Api.bmMemeberAdd,
		data: params
	})
}
// 部门成员详情
export function detailbmMemeber(id, params) {
	return axios({
		method: 'get',
		url: Api.bmMemeberDetail + id,
		params: params
	})
}
// 部门成员编辑
export function editbmMemeber(id, params) {
	return axios({
		method: 'put',
		url: Api.bmMemeberEdit + id,
		data: params
	})
}
// 账号列表
export function getAccountList(params) {
	return axios({
		method: 'get',
		url: Api.accountList,
		params: params
	})
}
// 账号新增
export function addAccount(params) {
	return axios({
		method: 'post',
		url: Api.accountAdd,
		data: params
	})
}
// 账号详情
export function detailAccount(id, params) {
	return axios({
		method: 'get',
		url: Api.accountDetail + id,
		params: params
	})
}
// 账号编辑
export function editAccount(id, params) {
	return axios({
		method: 'put',
		url: Api.accountEdit + id,
		data: params
	})
}
// 分会角色
export function listFhRoles(params) {
	return axios({
		method: 'get',
		url: Api.fhRolesList,
		params: params
	})
}
// 小程序首图
export function imgMiniprogram(params) {
	return axios({
		method: 'get',
		url: Api.miniprogramImg,
		params: params
	})
}
// 小程序首图更换
export function changeImgMiniprogram(params) {
	return axios({
		method: 'get',
		url: Api.miniprogramImgChange,
		params: params
	})
}
// 操作日志
export function listHandleLog(params) {
	return axios({
		method: 'get',
		url: Api.handleLogList,
		params: params
	})
}
// 修改密码
export function changePassword(params) {
	return axios({
		method: 'post',
		url: Api.passwordChange,
		data: params
	})
}
// 获取总分会列表
export function allOrigin(params) {
	return axios({
		method: 'get',
		url: Api.originAll,
		params: params
	})
}
export function disableAccount(id, params) {
	return axios({
		method: 'put',
		url: Api.accountDisable + id,
		params: params
	})
}
export function deleteRoles(id, params) {
	return axios({
		method: 'delete',
		url: Api.rolesDelete + id,
		params: params
	})
}
export function deleteBmMember(id, params) {
	return axios({
		method: 'delete',
		url: Api.bmMemberDelete + id,
		params: params
	})
}
export function deleteAccount(id, params) {
	return axios({
		method: 'delete',
		url: Api.accountDelete + id,
		params: params
	})
}
